<template>

    <div v-if="!$route.params.projectId" style="animation-duration: .3s" key="list" class="w-2/3  px-4 flex flex-col py-2 relative ">
        
        <loader :loading="loading" />

        <div class="scroll-gradient"></div>

        <div class="flex items-center my-2 h-12 flex-none">

            <div class="h-full w-1/4 rounded-lg bg-body my-2">
                <input type="text" placeholder="Search" v-model="searchInput" class="w-full h-full rounded text-lg bg-body flex
                    flex-row justify-start items-center px-2">
            </div>
            <div class="w-96 px-2 flex items-center ml-auto">
                <div class="flex-none text-font-gray mr-2">Project Status</div>
                <multiselect v-model="projectStatusFiltersSelected"
                    :options="projectStatusFilters" label="filter_name"/>
            </div>
            <div  v-on:click="setAlertPositiveOrNegative" class="header-btn">
                <i v-if="!this.alertPositiveOrNegative" class="mdi mdi-thumb-up-outline"></i>
                <i v-else-if=" this.alertPositiveOrNegative==='positive'" class="mdi mdi-thumb-up"></i>
                <i v-else-if=" this.alertPositiveOrNegative==='negative'" class="mdi mdi-thumb-down"></i>
            </div>
        </div>

        <div class="h-full overflow-auto no-scrollbar">

            <div class="h-auto mb-4 relative">

                <div v-if="projects_sales && projects_sales.data.length > 0" class="relative flex flex-col overflow-hidden" :class="{'h-48':!openSales, 'h-122':openSales}">

                    <div class="h-10 mb-2 flex items-center py-2" v-if="projects_sales.meta">
                        <div class="font-semibold flex items-center text-danger">
                            <div>Sales</div>
                            <div class="text-xl mx-2">{{ projects_sales.meta.to }} / {{projects_sales.meta.total}}</div>
                            <div><i class="mdi mdi-alert text-2xl"></i></div>
                        </div>
                        <div class="ml-3 text-sm text-aux cursor-pointer" v-if="projects_sales.data.length > 3 && !openSales"
                            @click="openSales=true">
                            (<span class="underline mx-1">View all</span>)
                        </div>
                        <div class="ml-3 text-sm text-aux cursor-pointer" v-if="projects_sales.data.length > 3 && openSales"
                            @click="openSales=false">
                            (<span class="underline mx-1">Collapse</span>)
                        </div>
                    </div>

                    <div v-if="projects_sales" class="flex-1 min-h-0 overflow-auto flex flex-wrap content-start grid grid-cols-3 gap-4 mb-4 mt-auto relative" :class="{'overflow-hidden': !openSales}">
                    
                        <alert-project v-for="(el, index) in searchProject(projects_sales.data,'project_name')" :key="index + el" :alert="el" />

                    </div>

                    <div v-if="openSales && projects_sales.meta.last_page!==projects_sales.meta.current_page" class="btn-gray" @click="nextPage('Sales')">MORE</div>

                </div>

                <div v-else-if="loading_sales" class="h-48 relative">
                    <loader :loading="loading_sales"/>
                </div>

            </div>

            <div class="h-auto mb-4 relative">

                <div v-if="projects_margin && projects_margin.data.length > 0" class="relative flex flex-col overflow-hidden" :class="{'h-48':!openMargin, 'h-122':openMargin}">

                    <loader :loading="loading_margin"/>

                    <div class="h-10 mb-2 flex items-center py-2" v-if="projects_margin.meta">
                        <div class="font-semibold flex items-center text-danger">
                            <div>Initial Margin vs Current Margin</div>
                            <div class="text-xl mx-2">{{ projects_margin.meta.to }} / {{projects_margin.meta.total}}</div>
                            <div><i class="mdi mdi-alert text-2xl"></i></div>
                        </div>
                        <div class="ml-3 text-sm text-aux cursor-pointer" v-if="projects_margin.data.length > 3 && !openMargin"
                            @click="openMargin=true">
                            (<span class="underline mx-1">View all</span>)
                        </div>
                        <div class="ml-3 text-sm text-aux cursor-pointer" v-if="projects_margin.data.length > 3 && openMargin"
                            @click="openMargin=false">
                            (<span class="underline mx-1">Collapse</span>)
                        </div>
                    </div>

                    <div v-if="projects_margin" class="flex-1 min-h-0 overflow-auto flex flex-wrap content-start grid grid-cols-3 gap-4 mb-4 mt-auto relative" :class="{'overflow-hidden': !openMargin}">
                    
                        <alert-project v-for="(el, index) in searchProject(projects_margin.data,'project_name')" :key="index + el" :alert="el" />

                    </div>

                    <div v-if="openMargin && projects_margin.meta.last_page!==projects_margin.meta.current_page" class="btn-gray" @click="nextPage('Initial Margin vs Current Margin')">MORE</div>

                </div>

                <div v-else-if="loading_margin" class="h-48 relative">
                    <loader :loading="loading_margin"/>
                </div>

            </div>

            <div class="h-auto mb-4 relative" v-if="(this.alertPositiveOrNegative === 'negative' || this.alertPositiveOrNegative === null)">

                <div v-if="projects_revenue_days && projects_revenue_days.data.length > 0" class="relative flex flex-col overflow-hidden" :class="{'h-48':!openRevenue, 'h-122':openRevenue}">

                    <loader :loading="loading_revenue_days"/>

                    <div class="h-10 mb-2 flex items-center py-2" v-if="projects_revenue_days.meta">
                        <div class="font-semibold flex items-center text-danger">
                            <div>Revenue Days</div>
                            <div class="text-xl mx-2">{{ projects_revenue_days.meta.to }} / {{projects_revenue_days.meta.total}}</div>
                            <div><i class="mdi mdi-alert text-2xl"></i></div>
                        </div>
                        <div class="ml-3 text-sm text-aux cursor-pointer" v-if="projects_revenue_days.data.length > 3 && !openRevenue"
                            @click="openRevenue=true">
                            (<span class="underline mx-1">View all</span>)
                        </div>
                        <div class="ml-3 text-sm text-aux cursor-pointer" v-if="projects_revenue_days.data.length > 3 && openRevenue"
                            @click="openRevenue=false">
                            (<span class="underline mx-1">Collapse</span>)
                        </div>
                    </div>

                    <div v-if="projects_revenue_days" class="flex-1 min-h-0 overflow-auto flex flex-wrap content-start grid grid-cols-3 gap-4 mb-4 mt-auto relative" :class="{'overflow-hidden': !openRevenue}">
                    
                        <alert-project v-for="(el, index) in searchProject(projects_revenue_days.data,'project_name')" :key="index + el" :alert="el" />

                    </div>

                    <div v-if="openRevenue && projects_revenue_days.meta.last_page!==projects_revenue_days.meta.current_page" class="btn-gray" @click="nextPage('Revenue Days')">MORE</div>

                </div>

                <div v-else-if="loading_revenue_days" class="h-48 relative">
                    <loader :loading="loading_revenue_days"/>
                </div>

            </div>

            <div class="h-auto mb-4 relative" >

                <div v-if="projects && projects.data.length > 0" class="relative flex flex-col overflow-hidden" :class="{'h-48':!openProjects, 'h-122':openProjects}">

                    <loader :loading="loading_projects"/>

                    <div class="h-10 mb-2 flex items-center py-2" v-if="projects.meta">
                        <div class="font-semibold flex items-center text-white">
                            <div>Projects</div>
                            <div class="text-xl mx-2">{{ projects.meta.to }} / {{projects.meta.total}}</div>
                        </div>
                        <div class="ml-3 text-sm text-aux cursor-pointer" v-if="projects.data.length > 3 && !openProjects"
                            @click="openProjects=true">
                            (<span class="underline mx-1">View all</span>)
                        </div>
                        <div class="ml-3 text-sm text-aux cursor-pointer" v-if="projects.data.length > 3 && openProjects"
                            @click="openProjects=false">
                            (<span class="underline mx-1">Collapse</span>)
                        </div>
                    </div>

                    <div v-if="projects" class="flex-1 min-h-0 overflow-auto flex flex-wrap content-start grid grid-cols-3 gap-4 mb-4 mt-auto relative" :class="{'overflow-hidden': !openProjects}">
                    
                        <project v-for="(el, index) in searchProject(projects.data,'project_n')"  :key="'project-' + index" :data="el" />

                    </div>

                    <div v-if="openProjects && projects.meta.last_page!==projects.meta.current_page" class="btn-gray" @click="nextPage('Projects')">MORE</div>

                </div>

                <div v-else-if="loading_projects" class="h-48 relative">
                    <loader :loading="loading_projects" />
                </div>

            </div>

        </div>

    </div>

</template>

<script>
    import { state } from '@/store';
    import axios from 'axios'
    const AlertBoxProjects = () => import('@/components/Desktop/ProjectsManagement/AlertBoxProjects');
    const AlertProject = () => import('@/components/Desktop/ProjectsManagement/AlertProject.vue');
    const Project = () => import('@/components/Desktop/ProjectsManagement/Project.vue')
    let cancel;
    let cancelRevenueDays;
    let cancelProject;
    let cancelSales;
    let cancelFilters;
    const CancelToken = axios.CancelToken;
    const CancelTokenProjectsRevenue = axios.CancelToken;
    const CancelTokenProject = axios.CancelToken;
    const CancelTokenSales = axios.CancelToken;
    const CancelTokenFilters = axios.CancelToken;

    export default {
        name: "AllProjects",
        components:{
            AlertBoxProjects,
            AlertProject,
            Project
        },
        data() {
            return {
                query: "",
                results: "",
                isLoading: false,
                queryRevenueDays: "",
                resultsRevenueDays: "",
                isLoadingRevenueDays: false,
                queryProject: "",
                resultsProject: "",
                isLoadingProject: false,
                querySales: "",
                resultsSales: "",
                isLoadingSales: false,
                queryFilters: "",
                resultsFilters: "",
                isLoadingFilters: false,
                stringFilter: '',
                loading: false,
                stringModel: '',
                data: null,
                alerts: [],
                projects: false,
                projectStatusFilters: [],
                projectStatusFiltersSelected: null,
                projects_margin: false,
                projects_billing: false,
                projects_revenue_days: false,
                projects_sales: false,
                page_projects: 1,
                page_projects_margin: 1,
                page_projects_billing: 1,
                page_projects_revenue_days: 1,
                page_projects_sales: 1,
                loading_projects: false,
                loading_margin: false,
                loading_billing: false,
                loading_revenue_days: false,
                loading_sales: false,
                searchInput: '',
                alertPositiveOrNegative: 'negative',
                openSales:false,
                openMargin:false,
                openRevenue:false,
                openProjects:false
            }
        },
        computed: {
            params() {
                if (this.$route.params.projectId) {
                    return {...state.globalParams,...{idProject: this.$route.params.projectId}}
                }
                return state.globalParams
            },
            param_status() {
                if (this.projectStatusFiltersSelected && this.projectStatusFiltersSelected.filter_id) {
                    return [this.projectStatusFiltersSelected.filter_id]
                }
                else { return null }
            },
            param_project_name() {
                if (this.searchInput !== '') { return this.searchInput }
                else { return null }
            },
            param_alert_positive_or_negative() {
                if (this.alertPositiveOrNegative !== '') { return this.alertPositiveOrNegative }
                else { return null }
            },
            call_params() {
                return {
                    ...this.params,
                    status: this.param_status,
                    project_name: this.param_project_name,
                    alert_positive_or_negative:this.param_alert_positive_or_negative
                }
            },
            urlParams(){
                return this.$route.params.projectId
            }
        },
        methods: {
            clear() {
                this.isLoading = false;
                this.results = "";
                this.query = "";
            },
            clearProjectsRevenueDays() {
                this.isLoadingRevenueDays = false;
                this.resultsRevenueDays = "";
                this.queryRevenueDays = "";
            },
            clearProject() {
                this.isLoadingProject = false;
                this.resultsProject = "";
                this.queryProject = "";
            },
            clearSales() {
                this.isLoadingSales = false;
                this.resultsSales = "";
                this.querySales = "";
            },
            clearFilters() {
                this.isLoadingFilters = false;
                this.resultsFilters = "";
                this.queryFilters = "";
            },
            handleSearch: _.debounce(function() {
                this.preApiCall();
            }, 300),
            handleSearchRevenueDays: _.debounce(function() {
                this.preApiProjectsCallRevenueDays();
            }, 300),
            handleSearchProject: _.debounce(function() {
                this.preApiProjectsCallProject();
            }, 300),
            handleSearchSales: _.debounce(function() {
                this.preApiProjectsSales();
            }, 300),
            handleSearchFilters: _.debounce(function() {
                this.preApiProjectsFilters();
            }, 300),
            preApiProjectsFilters() {
                if (cancelFilters !== undefined) {
                    cancelFilters();
                }
                this.queryFilters = {
                    ...this.call_params
                }
                this.loadProjectsFilters(this.querySales);
            },
            preApiProjectsSales() {
                if (cancelSales !== undefined) {
                    cancelSales();
                }
                this.querySales = {
                    ...this.call_params,
                    page: this.page_projects_sales
                }
                this.loadProjectsSales(this.querySales);
            },
            preApiProjectsCallRevenueDays() {
                if (cancelRevenueDays !== undefined) {
                    cancelRevenueDays();
                }
                this.queryRevenueDays = {
                    ...this.call_params,
                    page: this.page_projects_revenue_days
                }
                this.loadProjectsRevenueDays(this.queryRevenueDays);
            },
            preApiProjectsCallProject() {
                if (cancelProject !== undefined) {
                    cancelProject();
                }
                this.queryProject = {
                    ...this.call_params,
                    page: this.page_projects
                }
                this.loadProjects(this.queryProject);
            },
            preApiCall() {
                if (cancel !== undefined) {
                    cancel();
                }
                this.query = {
                    ...this.call_params,
                    page: this.page_projects_margin
                }
                this.loadProjectsMargin(this.query);
            },
            setAlertPositiveOrNegative(){
                if(this.alertPositiveOrNegative==='negative'){
                    this.alertPositiveOrNegative='positive'
                }else if(this.alertPositiveOrNegative==='positive'){
                    this.alertPositiveOrNegative=null;
                }else{
                    this.alertPositiveOrNegative='negative';
                }
            },
            setStringFilter(stringModel) {
                if (stringModel) { this.stringFilter = stringModel }
                else {
                    this.stringFilter = ''
                    this.stringModel = ''
                }
            },
            nextPage(name) {
                switch(name) {
                    case 'Projects':
                        this.page_projects++
                        break;
                    case 'Initial Margin vs Current Margin':
                        this.page_projects_margin++
                        break;
                    case 'Billing':
                        this.page_projects_billing++
                        break;
                    case 'Revenue Days':
                        this.page_projects_revenue_days++
                        break;
                    case 'Sales':
                        this.page_projects_sales++
                        break;
                }

            },
            collapse(name) {
                switch(name) {
                    case 'Projects':
                        this.page_projects = 1
                        break;
                    case 'Initial Margin vs Current Margin':
                        this.page_projects_margin = 1
                        break;
                    case 'Billing':
                        this.page_projects_billing = 1
                        break;
                    case 'Revenue Days':
                        this.page_projects_revenue_days = 1
                        break;
                    case 'Sales':
                        this.page_projects_sales = 1
                        break;
                }
            },
            loadProjects(query) {
                this.loading_projects = true
                if (query !== "") {
                    this.isLoadingProject = true;
                    this.axios({
                        method: "get",
                        url: "projects_management/project",
                        cancelToken: new CancelTokenProject(function executor(c) {
                            cancelProject = c;
                        }),
                        params: { ...query }
                    }).then(response => {
                        if (this.page_projects === 1) { this.projects = response.data }
                        else {
                            this.projects.meta = response.data.meta
                            for (let index = 0; index < response.data.data.length; index++) {
                                this.projects.data.push(response.data.data[index])
                            }
                        }
                        this.loading_projects = false
                    }).catch(err => {
                        this.isLoadingProject = false;
                        this.resultsProject = err.message;
                    })
                }
                else {
                    this.clearProject()
                }
            },
            loadProjectsMargin(query) {
                this.loading_margin = true
                if (query !== "") {
                    this.isLoading = true;
                    this.axios({
                        method: "get",
                        url: "projects_management/project_margin",
                        cancelToken: new CancelToken(function executor(c) {
                            cancel = c;
                        }),
                        params: { ...query }
                    }).then(response => {
                            if (this.page_projects_margin === 1) {
                                this.projects_margin = response.data
                                this.isLoading = false;
                            } else {
                                this.projects_margin.meta = response.data.meta
                                for (let index = 0; index < response.data.data.length; index++) {
                                    this.projects_margin.data.push(response.data.data[index])
                                }
                            }
                            this.loading_margin = false
                    }).catch(err => {
                        this.isLoading = false;
                        this.results = err.message;
                    })
                }
                else {
                    this.clear()
                }
            },
            loadProjectsBilling() {
                this.loading_billing = true
                this.axios.get('projects_management/project_billing', { params: {...this.call_params, page: this.page_projects_billing} })
                    .then(response => {
                        if (this.page_projects_billing === 1) { this.projects_billing = response.data }
                        else {
                            this.projects_billing.meta = response.data.meta
                            for (let index = 0; index < response.data.data.length; index++) {
                                this.projects_billing.data.push(response.data.data[index])
                            }
                        }
                        this.loading_billing = false
                    })
            },
            loadProjectsRevenueDays(query) {
                this.loading_revenue_days = true

                if (query !== "") {
                    this.isLoadingRevenueDays = true;
                    this.axios({
                        method: "get",
                        url: "projects_management/project_revenue_days",
                        cancelToken: new CancelTokenProjectsRevenue(function executor(c) {
                            cancelRevenueDays = c;
                        }),
                        params: { ...query }
                    }).then(response => {
                        if (this.page_projects_revenue_days === 1) { this.projects_revenue_days = response.data }
                        else {
                            this.projects_revenue_days.meta = response.data.meta
                            for (let index = 0; index < response.data.data.length; index++) {
                                this.projects_revenue_days.data.push(response.data.data[index])
                            }
                        }
                        this.loading_revenue_days = false
                    }).catch(err => {
                        this.isLoadingRevenueDays = false;
                        this.resultsRevenueDays = err.message;
                    })
                }
                else {
                    this.clearProjectsRevenueDays()
                }
            },
            loadProjectsSales(query) {
                this.loading_sales = true

                if (query !== "") {
                    this.isLoadingRevenueDays = true;
                    this.axios({
                        method: "get",
                        url: "projects_management/project_sales",
                        cancelToken: new CancelTokenSales(function executor(c) {
                            cancelSales = c;
                        }),
                        params: { ...query }
                    }).then(response => {
                        if (this.page_projects_sales === 1) { this.projects_sales = response.data }
                        else {
                            this.projects_sales.meta = response.data.meta
                            for (let index = 0; index < response.data.data.length; index++) {
                                this.projects_sales.data.push(response.data.data[index])
                            }
                        }
                        this.loading_sales = false
                    }).catch(err => {
                        this.isLoadingSales = false;
                        this.resultsSales = err.message;
                    })
                }
                else {
                    this.clearSales()
                }
            },
            loadProjectsFilters(query) {
                // this.loading = true
                if (query !== "") {
                    this.isLoadingFilters = true;
                    this.axios({
                        method: "get",
                        url: "projects_management/project_filters",
                        cancelToken: new CancelTokenFilters(function executor(c) {
                            cancelFilters = c;
                        }),
                        params: { ...query }
                    }).then(response => {
                        this.projectStatusFilters = response.data.filters.status;
                        let obj = {
                            'filter_id': null,
                            'filter_name': 'Todos'
                        }
                        this.projectStatusFilters.unshift(obj);
                    }).catch(err => {
                        this.isLoadingFilters = false;
                        this.resultsFilters = err.message;
                    })
                }
                else {
                    this.clearFilters()
                }
            },
            load() {
                if (this.alertPositiveOrNegative === 'negative' || this.alertPositiveOrNegative === null) {
                    this.preApiProjectsCallRevenueDays();
                }
                this.preApiProjectsCallProject();
                this.preApiCall();
                // this.loadProjectsBilling();
                this.preApiProjectsSales();
                this.preApiProjectsFilters();
            },
            search(data) {
                if (data) {
                    return data.filter(post => {
                        return post['name'].toLowerCase().includes(this.searchInput.toLowerCase())
                    })
                }
                else { return [] }
            },
            searchProject(data) {
                if (data) {
                    return data.filter(post => {
                        return post['project_name'].toLowerCase().includes(this.searchInput.toLowerCase()) || post['client_name'].toLowerCase().includes(this.searchInput.toLowerCase())
                    })
                }
                else { return [] }
            },
            resetPages(){
                this.page_projects = 1,
                this.page_projects_margin = 1,
                this.page_projects_billing = 1,
                this.page_projects_revenue_days = 1,
                this.page_projects_sales = 1
            }
        },
        mounted(){
            this.load()
        },
        watch:{
            params(n, o) {
                this.projects_sales = false
                this.load();
            },
            page_projects(n,o) {
                if (n <= this.projects.meta.last_page) { this.preApiProjectsCallProject() }
            },
            page_projects_margin(n,o) {
                if (n <= this.projects_margin.meta.last_page) { this.preApiCall() }
            },
            page_projects_billing(n,o) {
                if (n <= this.projects_billing.meta.last_page) { this.loadProjectsBilling() }
            },
            page_projects_revenue_days(n,o) {
                if (n <= this.projects_revenue_days.meta.last_page) { this.preApiProjectsCallRevenueDays() }
            },
            page_projects_sales(n,o) {
                if (n <= this.projects_sales.meta.last_page) { this.preApiProjectsSales() }
            },
            param_project_name(){ 
                this.load() 
            },
            param_alert_positive_or_negative() {
                this.load() 
            },
            param_status(){ 
                this.load() 
            },
            projectStatusFiltersSelected(){
                this.resetPages();
                this.openSales=false,
                this.openMargin=false,
                this.openRevenue=false,
                this.openProjects=false
                this.load();
            },
            urlParams(){
                this.page_projects_sales = 1;
            }
        }
    }
</script>